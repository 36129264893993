import axios from 'axios'
import { Toast } from '../utils/toast'

const api = axios.create({
  baseURL: 'https://backendomega.bmsltda.com.br'
})

api.interceptors.request.use((value) => {
  value.headers = {
    ...value.headers,
    Authorization: `Bearer ${localStorage.getItem('token')}`
  }
  return value
})

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.request.responseURL.includes('integration-whatsapp/send/message')) {
    return;
  }

  if (error?.response?.status === 400 && JSON.stringify(error?.response?.data).includes('E_INVALID_AUTH_UID')) {
    Toast.warning('E-mail ou senha incorretos', 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.status === 400 && error?.response?.data?.messsage === 'Usuário já existe') {
    Toast.warning('Já tem um usuário com esse CPF', 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.status === 400) {
    Toast.warning(error?.response?.data?.message, 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.data?.message === 'Este estoque já está sendo usado em um produto, não é possível mudar o tipo de unidade.') {
    Toast.warning('Este estoque já está sendo usado em um produto, não é possível mudar o tipo de unidade.', 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.status === 401 && error?.response?.data?.message === 'Cadastro bloqueado, entre em contato com suporte.') {
    Toast.warning('Cadastro bloqueado, entre em contato com a empresa.', 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.data?.message === 'Rating não encontrado') {
    return Promise.reject(error);
  }

  if (error?.response?.status === 413) {
    Toast.warning("Imagem com tamanho maior que o permitido. Utilize uma imagem menor.", 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.status === 422) {
    const [key] = Object.keys(error.response.data.erros[0])
    Toast.warning(error.response.data.erros[0][key] ?? "Erro de validação dos campos preenchidos.", 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.status === 401) {
    setTimeout(() => {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('plan')
      window.location.href = '/'
    }, 2000);
    if (!localStorage.getItem('ja_mostro'))
      Toast.warning("Acesso negado, você será redirecionado para a tela de login.", 'Validação')

    localStorage.setItem('ja_mostro', true)
    return Promise.reject(error);
  }

  if (error?.response?.data?.message === 'Este link já esta sendo usado') {
    Toast.warning("Este link já esta sendo usado", 'Validação')
    return Promise.reject(error);
  }

  if (error?.response?.data?.message === 'Loja fechada') {
    return Promise.reject(error);
  }

  if (error?.response?.data?.message === 'Faça a configuração de horário de funcionamento da empresa.') {
    const CONFIG_COMPANY = 'config_company'

    if (parseInt(localStorage.getItem(CONFIG_COMPANY) ?? 0) < 5) {
      Toast.warning(error.response.data.message)
    } else {
      return Promise.reject(error);
    }

    const exists = localStorage.getItem(CONFIG_COMPANY)

    if (exists === null) {
      localStorage.setItem(CONFIG_COMPANY, 1)
    }
    else {
      localStorage.setItem(CONFIG_COMPANY, parseInt(exists) + 1)
    }

    return Promise.reject(error);
  }

  if (error?.response?.data?.message) {
    Toast.warning(error.response.data.message)
    return Promise.reject(error);
  }



  if (error?.response?.status === 400) {
    Toast.warning(error.response.data.message)
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

export { api }

import { NavBar } from "../../components/NavBar";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

export function SchedulePage() {
  return (
    <>
      <NavBar>
        <div className="container-fluid">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            nowIndicator={true}
            height={'80vh'}
            allDayText='24 horas'
            columnFormat='dddd'
            header={{
              left: 'prev,next today',
              center: 'title',
              right: 'month,agendaWeek,agendaDay,listMonth'
            }}
            buttonText={{
              today: 'Hoje',
              month: 'Mês',
              week: 'Semana',
              day: 'Hoje',
              list: 'Lista',
            }}
            locale={'pt-BR'}
          />
        </div>
      </NavBar>
    </>
  )
}
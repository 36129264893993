import { HomeHome } from "./pages/home";
import { MatriculasPage } from "./pages/matriculas"

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { SchedulePage } from "./pages/schedule";
import { LoginPage } from "./pages/login";
import { GlobalProvider } from "./providers/GlobalProvider";
import { SubscribePage } from "./pages/subscribe";
import { Register } from "./pages/register";

const router = createBrowserRouter([
  {
    path: "/matricula",
    element: <MatriculasPage />,
  },
  {
    path: "/agenda",
    element: <SchedulePage />,
  },
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/home",
    element: <HomeHome />,
  },
  {
    path: '/subscription',
    element: <SubscribePage />
  },
  {
    path: '/register',
    element: <Register />
  },
]);

function App() {
  return (
    <GlobalProvider>
      <RouterProvider router={router} />
    </GlobalProvider>
  )
}

export default App

import { FaCheckCircle } from "react-icons/fa";
import { NavBar } from "../../components/NavBar";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../providers/GlobalProvider";
import { api } from "../../api/api";
import { CiNoWaitingSign } from "react-icons/ci";
import { Toast } from "../../utils/toast";
import FileBase64 from '../../components/FileBase64';

function Card({ nameCourse, isPedding, name, phone, downloadContract, downloadBoleto, data }) {
  const documentsValids = Object.keys(data?.documentsApproved.documentsValids ?? {}).filter((key) => {
    return data?.documentsApproved.documentsValids[key] === false
  })

  return (
    <>
      <div className="text-center border p-4 bg-white" style={{ borderRadius: 10, maxWidth: 700, width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: "center" }}>
          <div className="">
            <h5 style={{ fontWeight: "bold" }}>{nameCourse}</h5>
          </div>
          {isPedding && (
            <div>
              <CiNoWaitingSign size={30} color="orange" />
            </div>
          )}

          {!isPedding && (
            <div>
              <FaCheckCircle size={30} color="green" />
            </div>
          )}
        </div>

        <img width={100} src="/img/omega.7041f9d3.png" alt="" />

        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end' }}>
          <div style={{ textAlign: "start" }}>
            <p >Nome: <b>{name}</b></p>
            <p>Telefone: <b>{phone}</b></p>
            <p>Status da Matrícula: <b>{isPedding ? 'Pendente' : 'Aprovado'}</b></p>
            {isPedding && <p>Motivo: <b className="text-danger">{data?.documentsApproved?.observation}</b> </p>}
          </div>
          <div style={{ textAlign: "end" }}>
            <img src="/img/1_y0r718mO33GhYsTIT2wvGQ.gif" width={250} alt="" />
          </div>
        </div>

        <div className="col-sm-12 border rounded mt-2 mb-3">
          {documentsValids.map((doc) => {
            const aux = {
              rgresp: 'RG do responsável',
              rgFiador: 'RG do fiador [Frente]',
              cpfresp: 'CPF do responsável',
              cpfFiador: 'CPF do fiador',
              'rgresp-verso': 'RG do responsavel [Verso]',
              'rgFiador-verso': 'RG do fiador [Verso]',
              comproResiResp: 'Comprovante de residência do responsável',
              comproResiFiador: 'Comprovante de residência do fiador',
              pix: 'Pix',
              rg: 'RG do aluno [Frente]',
              'rg-verso': 'RG do aluno [Verso]',
              cpf: 'CPF do aluno',
              compResi: 'Comprovante de residência do aluno',
              compResiOrigem: 'Comprovante de residência do aluno (cidade de origem)',
            };

            return (
              <div className="text-left mt-2 mb-3" key={doc}>
                <label htmlFor="">{aux[doc]}</label>
                <br />
                <FileBase64
                  multiple={false}
                  onDone={async function (e) {
                    await api.post('/api/v1/files/base64', {
                      id: data?.documentsApproved?.studentCourseId,
                      documents: [
                        {
                          base64: `${e.base64}`,
                          name: e.name,
                          key: doc
                        }
                      ]
                    })

                    Toast.success("Arquivo enviado com sucesso")
                  }} />
              </div>
            )
          })}
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <button className="btn btn-outline-primary" onClick={() => downloadContract()}>Baixar Contrato</button>
          <button className="btn btn-outline-secondary" onClick={() => downloadBoleto()}>Baixar boleto</button>
        </div>
      </div>
    </>
  )
}

export function MatriculasPage() {
  const { student } = useContext(GlobalContext)
  const [loading, setLoading] = useState(true)
  const [matriculas, setMatriculas] = useState([])

  async function getMatriculas() {
    const response = await api.get('/api/v1/students/' + student.id)

    setMatriculas(response.data.studentCourse)
    setLoading(false)
  }

  async function downloadContract(courseId, studentId) {
    const response = await api.get(`/api/v1/contracts/${studentId}/${courseId}`)

    if (response.data.isDefault) {
      return Toast.warning('Seu contrato ainda não foi gerado, aguarde até o mesmo ser concluido.', 'Aguarde')
    }

    const win = window.open(api.getUri() + `/api/v1/contracts/render/${studentId}/${courseId}`)
    win.print()
  }

  async function downloadBoleto(studentId) {
    const response = await api.get(`/api/v1/files/download?refId=${studentId}&fileType=boleto`)
    
    if (!response.data.url) {
      return Toast.warning('Boleto ainda não gerado', '')
    }

    const link = document.createElement('a');
    link.href = response.data.url;
    link.target = '_blank'
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  useEffect(() => {
    if (student?.id) {
      getMatriculas()
    }
  }, [student?.id])

  return (
    <>
      <NavBar>
        <div className="container-fluid">
          <h4 className="text-center">Matrículas</h4>

          <br />

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10 }}>

            {loading && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            )}

            {!loading && (
              matriculas.map((matricula) => {
                return (
                  <Card
                    isPedding={['pendente', 'reprovado'].includes(matricula.documentsApproved.status)}
                    name={student.name}
                    nameCourse={matricula.courseName}
                    phone={student.phone}
                    key={matricula.id}
                    downloadContract={async () => await downloadContract(matricula.courseId, matricula.studentId)}
                    downloadBoleto={async () => await downloadBoleto(matricula.courseId, matricula.studentId)}
                    data={matricula}
                  />
                )
              })
            )}
          </div>
        </div>
      </NavBar>
    </>
  )
}
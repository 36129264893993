import { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";

import { GlobalContext } from '../../providers/GlobalProvider'
import { Toast } from '../../utils/toast'
import { api } from '../../api/api'
import './style.css'

export function LoginPage() {
  const navigate = useNavigate()
  const { setStudent } = useContext(GlobalContext)
  const [user, setUser] = useState({
    cpf: '',
    password: ''
  })

  async function auth() {
    if (!user.cpf || !user.password) {
      return Toast.warning("Preencha todos dados para acessar.")
    }

    const response = await api.post('/api/v1/auth', user)


    setStudent(response.data.student[0])
    localStorage.setItem('token', response.data.token.token)
    navigate('/home')
  }
  return (
    <>
      <div className="login-form shadow" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", height: '100vh' }}>
        <div className="mb-2" style={{ boxShadow: '5px 20px 50px #000', width: 350, height: 650, padding: '0 0 20px 0', borderRadius: 10, backgroundColor: 'white' }}>
          <img src="/img/dribbble.gif" style={{ width: '100%', borderTopLeftRadius: 8, borderTopRightRadius: 8 }} alt="" />

          <div style={{
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '60% / 10%',
            transform: 'translateY(-50px)',
            transition: '.8s ease-in-out',
            padding: 10,
          }}>
            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <img src="/img/omega.7041f9d3.png" width={150} alt="" />
            </div>

            <div>
              <label htmlFor="">CPF</label>
              <input value={user.cpf} onChange={(e) => setUser({ ...user, cpf: e.target.value })} type="text" className="form-control" />
            </div>
            <div>
              <label htmlFor="">Senha</label>
              <input value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} type="password" className="form-control" />
            </div>

            <button className="btn btn-sm btn-primary w-100 mb-3 mt-4 p-2" onClick={() => auth()}>Acessar</button>
            <button className="btn btn-sm btn-outline-primary w-100 mb-4 p-2" onClick={() => navigate('/register')}>Registre-se</button>

          </div>
        </div>
      </div>
    </>
  )
}
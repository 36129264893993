import { useNavigate } from 'react-router-dom'
import ReactInputMask from "react-input-mask"
import FileBase64 from '../../components/FileBase64'
import { useState } from 'react'

import './style.css'
import { api } from '../../api/api'

export function Register() {
  const navigate = useNavigate()
  const [user, setUser] = useState({
    name: '',
    cpf: '',
    senha: '',
    rg: '',
    telefone: '',
    email: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    cep: '',
    dataNascimento: '',
    documents: [],
    oldSchool: '',
    isSchoolPublic: 'Escola pública',
    isResideForaVicosa: 'Não',
    isStudentOfOmega: 'Não',
    isOldStudentOfOmega: 'Não'
  })

  async function register() {
    const response = await api.post('/api/v1/users/student', {
      'cpf': user.cpf,
      'rg': user.rg,
      'name': user.name,
      'birthDate': user.dataNascimento,
      'password': user.senha,
      'phone': user.telefone,
      'cep': user.cep,
      'address': user.endereco,
      'number': user.numero,
      'district': user.bairro,
      'city': user.cidade,
      'uf': user.uf,
      'isSchoolPublic': user.isSchoolPublic === 'Escola pública',
      'isResideForaVicosa': user.isResideForaVicosa === 'Sim',
      'isStudentOfOmega': user.isStudentOfOmega === 'Sim',
      'isOldStudentOfOmega': user.isOldStudentOfOmega === 'Sim',
      'documents': user.documents,
    })

    localStorage.setItem('token', response.data.token.token)
    navigate('/home')
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  }

  return (
    <>
      <div className="login-form" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", minHeight: '100vh', height: 'auto', }}>
        <div className="p-4 m-4 rounded" style={{ backgroundColor: 'white', width: 1000 }}>
          <div style={{}}>
            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <img src="/img/omega.7041f9d3.png" width={150} alt="" />
            </div>

            <div className="row border rounded pt-3 pb-3">
              <div className="col-sm-4 mb-2">
                <label htmlFor="">Nome</label>
                <input type="text"
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  placeholder="Nome completo"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-4 mb-2">
                <label htmlFor="">E-mail</label>
                <input
                  type="email"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  placeholder="E-mail"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-4 mb-2">
                <label htmlFor="">Telefone</label>
                <ReactInputMask
                  mask="(99) 9 9999-9999"
                  className="form-control form-control-sm"
                  placeholder="Digite seu telefone"
                  value={user.telefone}
                  onChange={(e) => setUser({ ...user, telefone: e.target.value })}
                />
              </div>
              <div className="col-sm-4 mb-2">
                <label htmlFor="">Endereço</label>
                <input
                  type="text"
                  value={user.endereco}
                  onChange={(e) => setUser({ ...user, endereco: e.target.value })}
                  placeholder="Endereço"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-2 mb-2">
                <label htmlFor="">Número</label>
                <input
                  type="text"
                  value={user.numero}
                  onChange={(e) => setUser({ ...user, numero: e.target.value })}
                  placeholder="Número"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-3 mb-2">
                <label htmlFor="">Bairro</label>
                <input
                  type="text"
                  value={user.bairro}
                  onChange={(e) => setUser({ ...user, bairro: e.target.value })}
                  placeholder="Bairro"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-3 mb-2">
                <label htmlFor="">Cidade</label>
                <input
                  type="text"
                  value={user.cidade}
                  onChange={(e) => setUser({ ...user, cidade: e.target.value })}
                  placeholder="Cidade"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-3 mb-2">
                <label htmlFor="">Cep</label>
                <ReactInputMask
                  mask="99999-999"
                  className="form-control form-control-sm"
                  placeholder="Digite seu cep"
                  value={user.cep}
                  onChange={(e) => setUser({ ...user, cep: e.target.value })}
                />
              </div>
              <div className="col-sm-3 mb-2">
                <label htmlFor="">Data Nascimento</label>
                <input
                  type="date"
                  value={user.dataNascimento}
                  onChange={(e) => setUser({ ...user, dataNascimento: e.target.value })}
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-3 mb-2">
                <label htmlFor="">CPF</label>
                <ReactInputMask
                  mask="999.999.999-99"
                  className="form-control form-control-sm"
                  value={user.cpf}
                  onChange={(e) => setUser({ ...user, cpf: e.target.value })}
                />
              </div>
              <div className="col-sm-3 mb-2">
                <label htmlFor="">Senha</label>
                <input
                  type="password"
                  value={user.senha}
                  onChange={(e) => setUser({ ...user, senha: e.target.value })}
                  placeholder="Senha"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-3 mb-2">
                <label htmlFor="">RG</label>
                <input
                  type="text"
                  value={user.rg}
                  onChange={(e) => setUser({ ...user, rg: e.target.value })}
                  placeholder="RG"
                  className="form-control form-control-sm"
                />
              </div>
              <div className="col-sm-4 mb-2">
                <label htmlFor="">Você concluiu o terceiro ano em:</label>
                <select
                  className="form-control form-control-sm"
                  value={user.isSchoolPublic}
                  onChange={(e) => setUser({ ...user, isSchoolPublic: e.target.value })}
                  aria-label="Default select example"
                >
                  <option value="Escola pública">Escola pública</option>
                  <option value="Escola particular">Escola particular</option>
                </select>
              </div>

              <div className="col-sm-4 mb-2">
                <label htmlFor="">Você é aluno do colégio ômega:</label>
                <select
                  value={user.isStudentOfOmega}
                  onChange={(e) => setUser({ ...user, isStudentOfOmega: e.target.value })}
                  className="form-control form-control-sm"
                  aria-label="Default select example"
                >
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </select>
              </div>

              <div className="col-sm-4 mb-2">
                <label htmlFor="">Você é ex aluno do colégio ômega:</label>
                <select
                  value={user.isOldStudentOfOmega}
                  onChange={(e) => setUser({ ...user, isOldStudentOfOmega: e.target.value })}
                  className="form-control form-control-sm"
                >
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </select>
              </div>
              <div className="col-sm-4 mb-2">
                <label htmlFor="">Reside fora de viçosa:</label>
                <select
                  value={user.isResideForaVicosa}
                  onChange={(e) => setUser({ ...user, isResideForaVicosa: e.target.value })}
                  className="form-control form-control-sm"
                >
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </select>
              </div>
            </div>

            <div className="col-sm-12 mt-4">
              <h3 className="text-center">Anexos de documentos</h3>
            </div>

            <div className="col-sm-12 row">
              <div className="col-sm-6 mt-3">
                <label>RG (Frente)</label><br />
                <FileBase64
                  multiple={false}
                  onDone={function (e) {
                    setUser({
                      ...user,
                      documents: [
                        ...user.documents,
                        {
                          base64: `${e.base64}`,
                          name: e.name,
                          key: 'rg'
                        }
                      ]
                    })
                  }} />
              </div>
              <div className="col-sm-6 mt-3">
                <label>RG (Verso)</label><br />
                <FileBase64
                  multiple={false}
                  onDone={function (e) {
                    setUser({
                      ...user,
                      documents: [
                        ...user.documents,
                        {
                          base64: `${e.base64}`,
                          name: e.name,
                          key: 'rg-verso'
                        }
                      ]
                    })
                  }} />
              </div>
              <div className="col-sm-6 mt-3">
                <label>CPF</label><br />

                <FileBase64
                  multiple={false}
                  onDone={function (e) {
                    setUser({
                      ...user,
                      documents: [
                        ...user.documents,
                        {
                          base64: `${e.base64}`,
                          name: e.name,
                          key: 'cpf'
                        }
                      ]
                    })
                  }} />
              </div>
              <div className="col-sm-6 mt-3">
                <label>Comprovante Residência</label><br />
                <FileBase64
                  multiple={false}
                  onDone={function (e) {
                    setUser({
                      ...user,
                      documents: [
                        ...user.documents,
                        {
                          base64: `${e.base64}`,
                          name: e.name,
                          key: 'compResi'
                        }
                      ]
                    })
                  }} />
              </div>
              <div className="col-sm-6 mt-3">
                <label>Comprovante Residência (Cidade de origem)</label><br />
                <FileBase64
                  multiple={false}
                  onDone={function (e) {
                    setUser({
                      ...user,
                      documents: [
                        ...user.documents,
                        {
                          base64: `${e.base64}`,
                          name: e.name,
                          key: 'compResiOrigem'
                        }
                      ]
                    })
                  }} />
              </div>
            </div>

            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20, gap: 20 }}>
              <button className="btn btn-sm btn-primary w-100" onClick={() => register()}>Registra-se</button>
              <button className="btn btn-sm btn-outline-primary w-100" onClick={() => navigate('/')}>Voltar para o login</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { api } from '../api/api'

export const GlobalContext = React.createContext({})

export const GlobalProvider = ({ children }) => {
  const [student, setStudent] = useState({})

  async function getStudent() {
    if (['/', '/register'].includes(window.location.pathname)) {
      return;
    }
    const response = await api.get('/api/v1/info')
    setStudent(response.data[0])
  }


  useEffect(() => {
    if (!student?.id)
      getStudent()

  }, [student?.id])

  return (
    <GlobalContext.Provider value={{ student, setStudent }}>
      {children}
    </GlobalContext.Provider>
  )
}
function mascaraMoeda(value) {
  if (!value) {
    return 0
  }

  const onlyDigits = value
    .split("")
    .filter(s => /\d/.test(s))
    .join("")
    .padStart(3, "0")

  const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)

  return parseFloat(digitsFloat)
}

function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
  if (isNaN(valor)) {
    valor = 0
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(valor)
}

function removeCaracteresEspeciais(str) {
  return str?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/-/ig, '_')
    .replace(/ /ig, '_') ?? ""
}

function numOrderFormat(numOrder) {
  if (numOrder === undefined || numOrder === null)
    return ''
  return String(numOrder).padStart(5, '0')
}

function convertValueTypeStock(type = null, value = 0) {
  if (!type) { return 0 }

  if (type === 'KG') {
    return `${value} KG`
  }

  if (type === 'UN') {
    return `${value} UN`
  }

  if (type === 'LT') {
    return `${value} LT`
  }
}

function downloadFile(url, name) {
  fetch(url)
    .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('Erro ao baixar arquivo'));
}

function extractPixValue(pixCode) {
  try {
    // Localiza a posição do identificador "54" no código Pix
    const valueIndex = pixCode.indexOf('54');

    // Obtém os dois próximos dígitos após o identificador, que indicam o tamanho do valor
    const lengthOfValue = parseInt(pixCode.substr(valueIndex + 2, 2), 10);

    // Extrai o valor com base no tamanho informado
    const value = pixCode.substr(valueIndex + 4, lengthOfValue);

    // Retorna o valor como um número
    return parseFloat(value);
  } catch (error) {
    return 0
  }
}


export { mascaraMoeda, maskCurrency, removeCaracteresEspeciais, numOrderFormat, convertValueTypeStock, downloadFile, extractPixValue }